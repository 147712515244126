import { GetStaticProps } from "next";
import getStaticPageData from "../_hoc/GetStaticPageData";
import CustomLinkComponent from "../components/common/CusotmLink/CustomLinkComponent";
import CustomImageComponent from '../components/common/CustomImage/CustomImageComponent';
import { getData, getTestingData } from "../lib/network/api";
import { ABTestingData, Custom404, DeepLinkData } from "../lib/types/Misc.type";
import { ICommonData, INavLinksData } from "../lib/types/common";
import {
    LIVE_WEB_URL,
    WINZO_GLOBAL_URL,
    getWebsiteEnvironment,
    BRAND_DOT_WINZOGAMES,
    WINZO_DOT_APP, GAMESMANIA_DOT_IN, WINFANTASY_DOT_IN
} from "../lib/utils/constants/EnvironmentConstants";
import { DEEP_LINK_JSON } from "../lib/utils/constants/GlobalConstants";
import { COUNTRY, LANGUAGE_COUNTRY } from "../lib/utils/constants/LocaleAndCountryConstants";
import { getCountryFromLocale } from "../lib/utils/constants/PagesConstants";

const getUrlLink = (link: string): string => {
  if([BRAND_DOT_WINZOGAMES,WINZO_DOT_APP, WINZO_GLOBAL_URL, WINFANTASY_DOT_IN, GAMESMANIA_DOT_IN].includes(getWebsiteEnvironment().baseUrl) && link.includes(LIVE_WEB_URL)) {
    const url = new URL(link)
    url.hostname = getWebsiteEnvironment().baseUrl
    return url.toString()
  }

  return link
}

const Custom404Page = (props: any) => {
    const data: Custom404 = props.apiData;

    // useEffect(() => {
    //     const path = pagePath();
    //     if(!data.deepLinksJson.DEEP_LINKS.includes(path)){
    //         ZOLogger.error({message: "404 not Found", pageName: path, params: pageParamsObject(), errorType: "404 not Found"})
    //     }
    // }, [])

    return (
        <>
            <div className={"mainContainer"}>
                <style jsx>{`
                  .mainContainer {
                    background-image: ${"url(" + data.bgImage.src + ")"};
                    position: relative;
                    min-height: 100vh;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    overflow: auto;
                  }

                  .mainHeading {
                    font-family: var(--font-primary);
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: italic;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #fff;
                    text-align: center;
                  }

                  .heading {
                    font-size: 60px;
                  }

                  .subheading {
                    font-size: 36px;
                  }

                  .text {
                    justify-content: center;
                    font-family: var(--font-primary);
                    font-size: 20px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: rgba(255, 255, 255, 0.7);
                    padding: 30px 0;
                    text-align: center;
                  }

                  .ulist {
                    margin: 30px 0;
                    white-space: nowrap;
                    display: flex;
                    list-style-type: none;
                    padding: 0;
                    flex-wrap: wrap;
                  }

                  .ulist li {
                    float: left;
                  }

                  .ulist li a {
                    display: block;
                    padding: 10px;
                  }

                  .ulist li {
                    font-family: var(--font-primary);
                    font-size: 28px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: italic;
                    line-height: normal;
                    color: #fff;
                  }

                  .ulist li span {
                    margin-left: 10px;
                  }

                  .rows {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 100px 0;
                  }
                  .cols {
                    flex-basis: 0;
                    flex-grow: 1;
                    max-width: 100%;
                    position: relative;
                    padding: 0 15px;
                    margin: auto 0;
                  }
                  .img-container {
                    justify-content: center;
                    align-items: center;
                    margin: 30px 0;
                  }
                  .mobile-heading-container {
                    display: none;
                    justify-content: center;
                  }
                  .desktop-heading-container {
                    margin: 30px 0;
                    justify-content: center;
                  }
                  .cover-image {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                  }
                  .cover-image .img {
                    width: 400px;
                    height: 350px;
                    position: relative;
                  }
                  .list-container {
                    margin: auto;
                  }
                  .li {
                    background: url('https://d3g4wmezrjkwkg.cloudfront.net/website/images/list_bullet.webp') no-repeat left top;
                    background-size: 24px;
                    padding: 0 30px;
                    margin: 10px;
                    max-width: 800px;
                  }
                  
                  @media screen and (max-width: 992px) {
                    .heading {
                      font-size: 48px;
                    }

                    .subheading {
                      font-size: 24px;
                    }

                    .text {
                      font-size: 16px;
                    }

                    .ulist {
                      margin: 20px 0;
                    }

                    .ulist li {
                      font-size: 20px;
                    }

                    .ulist li span {
                      margin-left: 7px;
                    }
                    .desktop-heading-container {
                      margin: 20px 0;
                    }
                    .li {
                      background-size: 20px;
                    }
                  }
                
                  @media screen and (max-width: 767px) {
                    .rows {
                      margin: 50px 0;
                    }
                    .cols {
                      flex: auto;
                    }
                    .img-container {
                      margin: 20px 0;
                    }
                    .mobile-heading-container {
                      display: flex;
                    }
                    .desktop-heading-container {
                      display: none;
                    }
                    .cover-image .img {
                      width: 300px;
                      height: 250px;
                    }
                    .li {
                      background-size: 18px;
                      padding: 0 20px;
                    }
                  }

                  @media screen and (max-width: 576px) {
                    .heading {
                      font-size: 36px;
                    }

                    .subheading {
                      font-size: 18px;
                    }

                    .text {
                      font-size: 14px;
                      padding: 20px;
                      text-align: center;
                    }

                    .ulist li {
                      font-size: 16px;
                    }

                    .li {
                      max-width: 300px;
                    }
                  }

                  @media screen and (max-width: 480px) {
                    .li {
                      background-size: 14px;
                      padding: 0 10px;
                    }
                  }
                `}
                </style>
                <div className={"container"}>
                    <div className="rows">
                        <div className="cols">
                            <div className="img-container">
                                <div className="mobile-heading-container">
                                    <h1 className={"mainHeading"}>
                                        <div className={"heading"}>{data.heading1}</div>
                                        <div className={"subheading"}>{data.heading2}</div>
                                    </h1>
                                </div>
                                <div className="cover-image">
                                  <div className="img">
                                    <CustomImageComponent
                                        layout={'fill'}
                                        objectFit={'contain'}
                                        src={data.coverImage.src}
                                        alt={data.coverImage.alt}
                                    />
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="cols">
                            <div className="desktop-heading-container">
                                <h1 className={"mainHeading"}>
                                    <div className={"heading"}>{data.heading1}</div>
                                    <div className={"subheading"}>{data.heading2}</div>
                                </h1>
                            </div>
                            <div className={"text"}>
                                {data.coverImageText}
                            </div>
                            <div className="list-container">
                                <ul className="ulist">
                                    {
                                        data.pageRedirect.filter((item: INavLinksData) => {
                                            if ([BRAND_DOT_WINZOGAMES,WINZO_DOT_APP, WINZO_GLOBAL_URL, WINFANTASY_DOT_IN, GAMESMANIA_DOT_IN].includes(getWebsiteEnvironment().baseUrl)) {
                                                return item.link.includes(LIVE_WEB_URL)
                                            } else {
                                                return true
                                            }
                                        }).map((item: INavLinksData, index: number) => (
                                              <div key={"page_redirect_" + index}>
                                                  {
                                                      item.outside ?
                                                          <a href={getUrlLink(item.link)} className="a">
                                                              <li key={index} className="li">
                                                                  <span>
                                                                      {item.title}
                                                                  </span>
                                                              </li>
                                                          </a>
                                                          :
                                                          <CustomLinkComponent href={item.link} key={index}>
                                                              <li key={index} className="li">
                                                                  <span>
                                                                      {item.title}
                                                                  </span>
                                                              </li>
                                                          </CustomLinkComponent>
                                                  }
                                              </div>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export const getStaticProps: GetStaticProps = async ({locale}) => {
    const pageName: string = '404';
    if (getCountryFromLocale(locale) === COUNTRY.INDIA) locale = LANGUAGE_COUNTRY.EN_IN;

    const getAdditionalData = async (data, commonData: ICommonData, abTestingData: ABTestingData) => {
        const content = {
            page_title: "404 Page Not Found",
            meta_description: "404 Page Not Found",
            meta_keywords: "404 Page Not Found",
        };
        const deepLinksJson = await getTestingData<DeepLinkData>(DEEP_LINK_JSON);
        return {content, deepLinksJson,data,commonData,abTestingData};
    }

    return await getStaticPageData<Custom404>(getData, pageName, pageName, pageName, locale, undefined,
        15, getAdditionalData);
};

export default Custom404Page;